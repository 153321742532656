import { buildGameVideoToObject } from 'components/FeedSelect/utils';
import { ROUTES } from 'constants/screens';
import { useDateFromURL } from 'hooks/useDateFromURL';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectDeepLink, selectIsAppInitialized, setDeepLink } from 'store/app';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectIsAuthenticated } from 'store/mlbAuth';
import { buildForgeVideoToObject } from 'utils/forgeVideo';

interface DeepLinkProps {
  contentId?: 'string';
  gamePk?: 'string';
  mediaId?: 'string';
  slug?: 'string';
}

export function useLaunchArguments() {
  const { date } = useDateFromURL();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAppInitialized = useAppSelector(selectIsAppInitialized);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const storeDeepLink = useAppSelector(selectDeepLink);

  const [lgDeepLink, setLGDeepLink] = useState<DeepLinkProps>({});

  const listener = ((event: CustomEvent) => {
    setLGDeepLink(event.detail);
  }) as EventListener;

  useEffect(() => {
    document.addEventListener('webOSLaunch', listener);
    document.addEventListener('webOSRelaunch', listener);

    return () => {
      document.removeEventListener('webOSLaunch', listener);
      document.removeEventListener('webOSRelaunch', listener);
    };
  });

  useEffect(() => {
    if (!isAppInitialized) return;

    setLGDeepLink(window.webOSDev.launchParams() ?? {});
  }, [isAppInitialized]);

  useEffect(() => {
    const jsonString = JSON.stringify(lgDeepLink);

    if (jsonString !== storeDeepLink) {
      dispatch(setDeepLink(jsonString));
      const { contentId, gamePk, mediaId, slug } = lgDeepLink;

      if (gamePk && contentId && mediaId) {
        if (!isAuthenticated) {
          navigate(ROUTES.WELCOME, {
            state: {
              date,
              from: ROUTES.GAMES,
              gamePk,
            },
          });
          return;
        }

        const toGame = buildGameVideoToObject({
          contentId,
          date,
          gamePk,
          mediaId,
        });
        navigate(toGame, {
          state: {
            date,
            from: ROUTES.GAMES,
            gamePk,
          },
        });
      }

      if (slug && !gamePk) {
        const to = buildForgeVideoToObject(slug);
        navigate(to, {
          state: {
            date,
            from: ROUTES.HOME,
          },
        });
      }
    }
  }, [date, dispatch, isAppInitialized, isAuthenticated, navigate, lgDeepLink, storeDeepLink]);
}
