import { useCallback, useEffect } from 'react';
import { setIsBackgrounded } from 'store/app';
import { useAppDispatch } from 'store/hooks';

export function useIsAppBackgrounded() {
  const dispatch = useAppDispatch();

  const callback = useCallback(() => {
    dispatch(setIsBackgrounded(document.hidden));
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener('visibilitychange', callback);

    return () => {
      document.removeEventListener('visibilitychange', callback);
    };
  }, [callback]);
}
